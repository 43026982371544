const initialState = {
  filters: [],
  search: '',
  infoSearch: '',
  modalData: {
    itemName: 'Default part',
    model: '',
    code: '',
    itemPrice: 25000,
    url: '/parts',
  },
  showModal: false,
};

// Add action creator

const ADD_FILTER = 'ADD_FILTER';
const REMOVE_FILTER = 'REMOVE_FILTER';
const SET_SEARCH = 'SET_SEARCH';
const CLEAR_SEARCH = 'CLEAR_SEARCH';
const SET_INFO_SEARCH = 'SET_INFO_SEARCH';
const CLEAR_INFO_SEARCH = 'CLEAR_INFO_SEARCH';
const UPDATE_FILTERS = 'UPDATE_FILTERS';
const UPDATE_CATEGORY_FILTERS = 'UPDATE_CATEGORY_FILTERS';
const UPDATE_MODEL_FILTERS = 'UPDATE_MODEL_FILTERS';
const TOGGLE_MODAL = 'TOGGLE_MODAL';
const SET_MODAL_DATA = 'SET_MODAL_DATA';
const CLEAR_MODAL_DATA = 'CLEAR_MODAL_DATA';

export const addFilter = filter => ({
  type: ADD_FILTER, filter
});

export const removeFilter = filter => ({
  type: REMOVE_FILTER, filter
});

export const updateFilters = filters => ({
  type: UPDATE_FILTERS, filters
});

export const updateCategoryFilters = filters => ({
  type: UPDATE_CATEGORY_FILTERS, filters
});

export const updateModelFilters = filters => ({
  type: UPDATE_MODEL_FILTERS, filters
});

export const setSearch = search => ({
  type: SET_SEARCH,
  search,
});

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
});

export const setInfoSearch = search => ({
  type: SET_INFO_SEARCH,
  search,
});

export const clearInfoSearch = () => ({
  type: CLEAR_INFO_SEARCH,
});

export const toggleModal = () => ({
  type: TOGGLE_MODAL,
});

export const setModalData = modalData => ({
  type: SET_MODAL_DATA,
  modalData
});

export const clearModalData = () => ({
  type: CLEAR_MODAL_DATA,
});

// Add reducer

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_FILTER:
      return { ...state, filters: [...state.filters, action.filter] };
    case REMOVE_FILTER:
      return { ...state, filters: [...state.filters.filter(el => el.value !== action.filter)] };
    case UPDATE_FILTERS:
      return { ...state, filters: action.filters }
    case UPDATE_CATEGORY_FILTERS:
      return { ...state, filters: [...state.filters.filter(el => el.type !== 'category'), ...action.filters] };
    case UPDATE_MODEL_FILTERS:
      return { ...state, filters: [...state.filters.filter(el => el.type !== 'model'), ...action.filters] };
    case SET_SEARCH:
      return { ...state, search: action.search };
    case CLEAR_SEARCH:
      return { ...state, search: '' };
    case SET_INFO_SEARCH:
      return { ...state, infoSearch: action.search };
    case CLEAR_INFO_SEARCH:
      return { ...state, infoSearch: '' };
    case TOGGLE_MODAL:
      return { ...state, showModal: !state.showModal };
    case SET_MODAL_DATA:
      return { ...state, modalData: action.modalData };
    case CLEAR_MODAL_DATA:
      return { ...state, modalData: {}};
    default:
      return state;
  }
};
