// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-jsx": () => import("./../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-news-item-jsx": () => import("./../src/templates/newsItem.jsx" /* webpackChunkName: "component---src-templates-news-item-jsx" */),
  "component---src-templates-news-list-jsx": () => import("./../src/templates/newsList.jsx" /* webpackChunkName: "component---src-templates-news-list-jsx" */),
  "component---src-templates-parts-item-jsx": () => import("./../src/templates/partsItem.jsx" /* webpackChunkName: "component---src-templates-parts-item-jsx" */),
  "component---src-templates-category-jsx": () => import("./../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-article-jsx": () => import("./../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-service-jsx": () => import("./../src/templates/service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-jsx": () => import("./../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-info-jsx": () => import("./../src/pages/info.jsx" /* webpackChunkName: "component---src-pages-info-jsx" */),
  "component---src-pages-parts-jsx": () => import("./../src/pages/parts.jsx" /* webpackChunkName: "component---src-pages-parts-jsx" */)
}

